html {
  font-size: 62.5%;
  box-sizing: border-box;
  margin: 0;
  /* overflow-x: hidden; */
  scroll-behavior: smooth;
}
/*16px * 62.5% = 10px = 1rem*/

body {
  margin: 0;
  height: 100vh;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  overflow-x: hidden;
}
#root {
  height: 100%;
}

/*-------------------------------------- Layout--------------------------------- */
.grid-container {
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 1fr 5rem;
  height: 100%;
}
header {
  grid-area: header;
  background-color: #1a1a1a;
  padding: 2px 20px 2px 30px;
  overflow: hidden;
  padding: 0 0 5px 0;
}

header li {
  font-size: 1.8rem;
}

main {
  grid-area: main;
  position: relative;
}

footer {
  grid-area: footer;
  /* background-color: #790024;*/
  color: #ffffff;
}

/*------------------------------- Common---------------------------------------- */
h1 {
  font-size: 3rem;
  padding: 1rem 0;
}
h2 {
  font-size: 2rem;
  padding: 1rem 0;
}

a {
  text-decoration: none;
}
/* a:hover {
  color: #ff8000;
} */

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

/* li {
  margin-top: 1rem;
} */

/* in forms*/
.select {
  padding: 1.5rem;
}

/*--------------------------------- Header------------------------------------- */
/* header a {
  color: #ffffff;
  padding: 1rem;
}
a.brand {
  color: #ffffff;
  font-size: 2.8rem;
  font-weight: bold;
  text-decoration: none;
} */

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.row.center {
  justify-content: center;
}
.row.top {
  align-items: flex-start;
}
.row.start {
  justify-self: flex-start;
}

/* this below one is for notification*/
.centerAlign {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.centerAlignCol {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/*------------------------------------ Image------------------------------------ */
img {
  border-radius: 0.5rem;
}
img.small {
  max-width: 5rem;
  width: 100%;
  margin: 0.5rem;
  border-radius: 50%;
}
img.medium {
  width: 100%;
  height: 23rem;
  max-width: 27rem;
}
img.large {
  width: 100%;
}

.avatar {
  vertical-align: middle;
  width: 75px;
  height: 75px;
  /* border-radius: 50%; */
}

/*-------------------------------------Product Card----------------------------------- */
.productCard {
  border: 0.1rem #c0c0c0 solid;
  text-align: center;
  background-color: #ffffff;
  border-radius: 1rem;
  margin: 2rem;
  padding: 0.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  transition: 0.3s;
  overflow: hidden;
}

.productCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
  transform: scale(1.03);
}
.productCard-body {
  padding: 1rem;
}
/* .card-body > * {
  margin-bottom: 00.5rem;
} */
.price {
  font-size: 2rem;
}

/*------------------------------------------------------ Genereal card-------------------------------------------------- */
.otherCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  transition: 0.3s;
  overflow: hidden;
  margin: 2rem;
  border: 0.2rem #c0c0c0 solid;
  text-align: center;
}
.singl_prod_button {
  border-radius: 30px;
}
/*-----------------------------------Cart-Badge--------------------------------------------*/

.cart-badge,
.cart-badge:hover {
  border-radius: 45%;
  padding: 2px;
  font-size: 13px;
  font-style: italic;
  background: #ffffff;
  color: black;
}
.cartContinueShoppingButton {
  color: white;
  background-color: rgb(33, 146, 5);
}
.cartContinueShoppingButton:hover {
  color: white;
  /* #0169f0 */
  background-color: rgb(6, 141, 18);
}
.purchaseHistoryButton {
  color: white;
  background-color: rgb(133, 128, 128);
}
.purchaseHistoryButton:hover {
  color: white;
  background-color: rgb(99, 95, 95);
}

/* ----------------------------------Filter--------------------------------------*/

.filterClass {
  background-color: #f8f8f8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  transition: 0.3s;
  margin-left: 1rem;
}

.btn {
  font-size: medium;
  padding-left: 1rem;
  padding-right: 1rem;
}
.form-control {
  font-size: medium;
}

/*---------------------------------- Checkout Steps--------------------------- */
.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1;
  padding: 1rem;
  font-weight: bold;
}
.checkout-steps > div.active {
  border-top-color: #ff0000;
  color: #ff0000;
}

/* input {
  width: 100%;
  padding: 10px;
  text-align: center;
}
.form-group {
  text-align: center;
} */

/*----------------------------------Product View carousel--------------------------------------------*/

.slider img {
  height: 400px;
  object-fit: cover;
}
@media screen and (max-width: 600px) {
  .slider img {
    max-height: 24rem;
  }
  .inputStyle {
    width: 100%;
  }
}

/*------------------------------------Home Carousel------------------------------------------------*/
img.carouselImg {
  object-fit: fill;
  max-height: 40rem;
  width: 100%;
  height: auto;
  border-radius: 0;
  /* max-width: 80rem; */
}
@media screen and (max-width: 600px) {
  img.carouselImg {
    max-height: 24rem;
  }
}
/*---------------------------------FontAwesom icons-------------------------------------------*/
/* trash icon*/
.fa-trash-alt {
  color: #a9a9a9;
}
.fa-trash-alt:hover {
  color: #e32636;
}
.fa-heart {
  color: red;
}
.fa-info-circle {
  color: #b9b4b4;
}
.fa-info-circle:hover {
  color: #ffd457;
}
i.fa-eye:hover {
  color: #00fcb6;
  cursor: pointer;
}

.fa-asterisk {
  color: palevioletred;
}

/* ---------------------------Table--------------------------------------------*/

.tableShadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  transition: 0.3s;
  overflow: hidden;
}

Link {
  pointer-events: none;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  input,
  select,
  textarea {
    font-size: 16px;
  }
}
/*---------------------------------------*/

.logoImg {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 100%;
  height: auto;
  max-width: 82px;
  max-height: 87px;
}

@media (min-width: 768px) {
  .logoImg {
    font-size: 3.5rem;
  }
}

/*-----------------------Tooltip----------------------*/
/**
 * Tooltip Styles
 */

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}
